import React from 'react';

import Layout from '../components/layout';

import { ReactComponent as NotFound } from '../assets/notfound.svg';

const NotFoundPage = () => (
  <Layout>
    <div className="page-404">
      <div className="message">
        <h1>OOps!</h1>
        <p>We can&#39;t seem to find the page you are looking for. Please check your URL or return to the <a href="/">Home Page</a>.</p>
        <NotFound />
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
